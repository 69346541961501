import Vue from 'vue'
import Router from 'vue-router'
import Utils from "@/utils/Utils";
import AuthService from '@/service/AuthService'

Vue.use(Router)
const routes = [
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    children: [
      // Dashboard
      {
        name: 'Dashboard',
        path: '',
        meta: { requiresAuth: true },
        component: () => import('@/views/dashboard/Dashboard'),
      },
      // Veicoli
      {
        name: 'Veicoli',
        path: 'pages/veicoli',
        meta: { requiresAuth: true },
        component: () => import('@/views/dashboard/pages/Veicoli'),
      },
      // Attrezzature
      {
        name: 'Attrezzature',
        path: 'pages/attrezzature',
        meta: { requiresAuth: true },
        component: () => import('@/views/dashboard/pages/Attrezzature'),
      },
      // Fornitori
      {
        name: 'Fornitori',
        path: 'pages/fornitori',
        meta: { requiresAuth: true },
        component: () => import('@/views/dashboard/pages/Fornitori'),
      },
      // Scadenze
      {
        name: 'Scadenze',
        path: 'pages/scadenze',
        component: () => import('@/views/dashboard/pages/Scadenze'),
      },
      // Rifornimenti
      {
        name: 'Rifornimenti',
        path: 'pages/rifornimenti',
        component: () => import('@/views/dashboard/pages/Rifornimenti'),
      },
      // Report
      {
        name: 'Report',
        path: 'pages/report',
        component: () => import('@/views/dashboard/pages/Report'),
      },
      // Emissione Buoni
      {
        name: 'Emissione Buoni',
        path: 'pages/EmissioneBuoni',
        component: () => import('@/views/dashboard/pages/EmissioneBuoni'),
      },
      // Notifiche
      {
        name: 'Notifiche',
        path: 'pages/notifiche',
        component: () => import('@/views/dashboard/pages/Notifiche'),
      },
      // Prenotazioni
      {
        path: 'pages/prenotazioni/',
        component: () => import('@/views/dashboard/pages/prenotazioni/Index'),
        children: [
          {
            name: 'Calendario Prenotazioni',
            path: 'calendarioprenotazioni',
            component: () => import('@/views/dashboard/pages/prenotazioni/CalendarioPrenotazioni'),
          },
          {
            name: 'Gestione Prenotazioni',
            path: 'gestioneprenotazioni',
            component: () => import('@/views/dashboard/pages/prenotazioni/GestionePrenotazioni'),
          },
        ]
      },

      // Sub Pages
      {
        path: 'pages/configurazioni/',
        component: () => import('@/views/dashboard/pages/configurazioni/Index'),
        children: [
          {
            name: 'Gestione tipologiche',
            path: 'tipologiche',
            component: () => import('@/views/dashboard/pages/configurazioni/Tipologiche'),
          },
          {
            name: 'Enti',
            path: 'enti',
            component: () => import('@/views/dashboard/pages/configurazioni/Enti'),
          },
          {
            name: 'Ubicazioni',
            path: 'ubicazioni',
            component: () => import('@/views/dashboard/pages/configurazioni/Ubicazioni'),
          },
          {
            name: 'Poli',
            path: 'poli',
            component: () => import('@/views/dashboard/pages/configurazioni/Poli'),
          },
          {
            name: 'Servizi',
            path: 'servizi',
            component: () => import('@/views/dashboard/pages/configurazioni/Servizi'),
          },
          {
            name: 'CapitoliSpesa',
            path: 'capitolispesa',
            component: () => import('@/views/dashboard/pages/configurazioni/CapitoliSpesa'),
          },
          {
            name: 'ProfilazioneUtenti',
            path: 'profilazioneutenti',
            component: () => import('@/views/dashboard/pages/configurazioni/ProfilazioneUtenti'),
          }
        ]
      },
      // Pages
      {
        name: 'User Profile',
        path: 'pages/user',
        component: () => import('@/views/dashboard/pages/UserProfile'),
      },
      {
        name: 'Notifications',
        path: 'components/notifications',
        component: () => import('@/views/dashboard/component/Notifications'),
      },
      {
        name: 'Icons',
        path: 'components/icons',
        component: () => import('@/views/dashboard/component/Icons'),
      },
      {
        name: 'Typography',
        path: 'components/typography',
        component: () => import('@/views/dashboard/component/Typography'),
      },
      // Tables
      {
        name: 'Regular Tables',
        path: 'tables/regular-tables',
        component: () => import('@/views/dashboard/tables/RegularTables'),
      },
      // Maps
      {
        name: 'Google Maps',
        path: 'maps/google-maps',
        component: () => import('@/views/dashboard/maps/GoogleMaps'),
      },
      // Upgrade
      {
        name: 'Upgrade',
        path: 'upgrade',
        component: () => import('@/views/dashboard/Upgrade'),
      },

    ],
  },
    // Login
    {
    name: 'Login',
    path: '/login',
    component: () => import('@/views/dashboard/pages/Login'),
  },
];

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  //se devo resettare la password vado subito in pagina ed effetuo logout
  /*if (to.name === "reset") {
    if (Utils.isNullOrUndefined(Utils.getParameterByName("rl"))) {
      next({ name: 'login' });
    } else {
      AuthService.logout();
      next();
    }
    return;
  }*/
  const isAuth = AuthService.isAuthenticatedUser();

  // se vado alla login ma sono già loggato -> projects
  if (to.name === "Login" && isAuth) {
    next({ name: 'Dashboard' });
    return;
  }

  let toPath = to.matched.filter(e => e.name === to.name)[0];
  if (Utils.isNullOrUndefined(toPath))
  {
    next({ name: 'Login' });
    return;
  }

  if (toPath.meta.requiresAuth || toPath.meta.grant) {
    // devo andare in una URL che necessita di essere loggato
    if (!isAuth && toPath.meta.requiresAuth) {
      // non ho le grant!!! -> login
      next({ name: 'Login' });
    } else {
      if (Utils.isNullOrUndefined(toPath.meta.grant)) {
        // la URL non necessita di grant ulteriori -> ok next
        next();
        return;
      }
/*
      if (environment.authority.some((r) => toPath.meta.grant.includes(r))) {
        // la URL necessita di grant ulteriori
        next();
      } else {
        next({ name: 'Dashboard' });
      }
*/
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
