import axios from 'axios'
import AuthService from '@/service/AuthService'
import router from '@/router'
import { EventBus } from '@/interceptor/EventBus'
import { environment } from '@/environment/environment'

const COURSE_API_URL = environment.urlSrc

export default class HttpInterceptor {
  apiNoLoading = []
  promisis = [];

  constructor() {
    let dom = this;

    // Request interceptor
    axios.interceptors.request.use((config) => {
      const jwtToken = AuthService.getToken();

      if (jwtToken) {
        config.headers['Authorization'] = 'Bearer ' + jwtToken;
      }

      //dom.addCall(config);

      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    // Response interceptor
    axios.interceptors.response.use((response) => {
      // TODO add instructions
      //dom.removeCall(response.config);

      return response;
    }, (error, a, b) => {
      //dom.removeCall(error.config);
      /*if(!AuthService.isAuthenticatedUser() || error.request.status === 401){
        AuthService.logout();
      }*//*else{
        EventBus.setSnackbar({
          msg: "error",
          success: false
        })
      }*/

      return Promise.reject(error);
    });
  }

  /*addCall(config){
    if(this.apiNoLoading.indexOf(config.url) > -1){
      return
    }

    this.promisis.push(config.url);
    EventBus.showPageLoader();
  }

  removeCall(config){
    if(this.apiNoLoading.indexOf(config.url) > -1){
      return
    }

    this.promisis.splice(this.promisis.indexOf(config.url), 1);

    if(this.promisis.length == 0){
      EventBus.hidePageLoader();
    }
  }*/
}