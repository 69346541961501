import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    drawer: null,
    user: JSON.parse(sessionStorage.getItem('user'))||null,
    roleSel: JSON.parse(sessionStorage.getItem('roleSel'))||null,
    roles: JSON.parse(sessionStorage.getItem('roles'))||null,
    permissions: JSON.parse(sessionStorage.getItem('permissions'))||null,
    enteSel: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_USER (state, payload) {
      state.user = payload;
      sessionStorage.setItem('user', JSON.stringify(payload))
    },
    SET_ROLES (state, payload) {
      state.roles = payload;
      sessionStorage.setItem('roles', JSON.stringify(payload))
    },
    SET_ROLE_SELECTED (state, payload) {
      state.roleSel = payload;
      sessionStorage.setItem('roleSel', JSON.stringify(payload))
    },
    SET_PERMISSIONS (state, payload) {
      state.permissions = payload;
      sessionStorage.setItem('permissions', JSON.stringify(payload))
    },
    SET_ENTE_SELECTED (state, payload) {
      state.enteSel = payload;
    },
  },
  actions: {

  },
})
