import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify)

const theme = {
  themes: {
    light: {
      background: colors.indigo.lighten5, // Not automatically applied
    },
    dark: {
      background: colors.indigo.base, // If not using lighten/darken, use base to return hex
    },
  
  },
  /*primary: '#4CAF50',*/
  primary: '#5c6169',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  info: '#00CAE3',
  darkred: '#be2816',
  lightpurple: '#e9e6f2',
  grey1: '#5c6169',
  darkgrey: '#40454f',
  sfondo: '#bdc3ce',
  selected: '#b3b7bf',

}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
