/**
 * Utils class
 */
 import AuthService from "@/service/AuthService";
 import i18n from "@/i18n";
 
class Utils {
	rules = {
		required: (value) => !this.isNullOrUndefinedOrEmpty(value) || i18n.t("campoObbligatorio"),
		selectRequired: (value) => !this.isNullOrUndefined(value) && value.id > 0 || i18n.t("campoObbligatorio"),
		valueMin: (value,min) => (this.isNullOrUndefinedOrEmpty(value) || value > min) || `Il valore deve essere maggiore di ${min}`,
		valueMax: (value,max) => (this.isNullOrUndefinedOrEmpty(value) || value <= max) || `Il valore deve essere minore di ${max}`,
		/*valueMin: (value,min) => {
			if (!this.isNullOrUndefinedOrEmpty(value) && value <= min) { 
				return `Il valore deve essere maggiore di ${min}`
			}
			return;
		},
		valueMax: (value,max) => {
			if (!this.isNullOrUndefinedOrEmpty(value) && value > max) { 
				return `Il valore deve essere minore di ${max}`
			}
			return;
		},*/
		//valueMax: (value,max) => value <= max || `Il valore deve essere minore di ${max}`,
		debug: (value) => console.log(value) || "debug",
		email: (value) => {
		  const pattern =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		  return pattern.test(value) || i18n.t("emailNonValida");
		},
		fileRules(value) {
			if (value != null)
				value.size < 10000000 || 'Il file non può superare i 10 MB!'
		},
	  }

	/**
	 * Checks if the current object is null or undefined. Returns true if it is
	 * either null or undefined else returns false
	 */
	isNullOrUndefined(object) {
		if ((typeof (object) === "undefined") || (object === null)) {
			return true;
		}
		return false;
	}

	isNullOrUndefinedOrEmpty(object) {
		if (this.isNullOrUndefined(object) || (object === '')) {
			return true;
		}
		return false;
	}

	areStringsEqual(stringA, stringB) {
		var resp = false;

		if (!this.isNullOrUndefined(stringA) && !this.isNullOrUndefined(stringB)) {
			if (stringA === stringB)
				resp = true;
		}

		return resp;
	}

	parseFloatInt(value) {
		if (isNaN(parseFloat(value))) {
			value = 0;
		}
		return value;
	}

	/**
	 * Verifica che la stringa non sia vuota
	 * @param string
	 * @returns {Boolean}
	 */

	isNotEmpty(string) {
		return !this.isNullOrUndefined(string) && "" !== string.trim();
	}

	isEmpty(string) {
		return !this.isNotEmpty(string);
	}

	isInt(n) {
		//return typeof n=== "number" && isFinite(n) && n%1===0;
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	isFloat(n) {
		return !isNaN(parseFloat(n));
	}

	isObject(n) {
		return typeof n === 'object' && n !== null;
	}

	isArray(n) {
		return Array.isArray(n);
	}

	isTrue(object) {
		if (!this.isNullOrUndefined(object) && (object === true || object === "true")) {
			return true;
		}
		return false;
	}

	createGuid() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	getErrorMessage(err) {
		if (!this.isNullOrUndefined(err.response)) {
			if (!this.isNullOrUndefined(err.response.data)) {
				if (!this.isNullOrUndefined(err.response.data.userMsg))
					if (!this.isNullOrUndefined(err.response.data.userMsg.message))
						return err.response.data.userMsg.message;
					else
						return err.response.data.userMsg;
				else
					return err.response.data;
			}
			else
				return err.response;
		}
		else
			return err;
	}

	verifyLoadingPage(store, pageAuth){
		if (!this.verifyUserData(store))
			return 1; // Utente non loggato
		
		if (this.isNullOrUndefinedOrEmpty(sessionStorage.getItem("user")))
			return 1; // Utente non loggato

		if (this.isNullOrUndefinedOrEmpty(store.state.enteSel)) 
			return 2; // Ente non selezionato

		if (!this.verifyUserPermission(store, pageAuth)) 
			return 3;

		return 0;
	}

	/// Verifica che i dati dell'utente memeorizzati nello store siano presenti
	verifyUserData(store) {
		if (this.isNullOrUndefinedOrEmpty(store))
		{
			return false;
		}
		else if (this.isNullOrUndefinedOrEmpty(store.state))
		{
			return false;
		}
		else if (this.isNullOrUndefinedOrEmpty(store.state.user))
		{
			return false;
		}
		
		return true;
	}

	/// Verifica se i permessi per l'utente sono definiti correttamente
	verifyUserPermission(store, pageAuth) {
		if (this.isNullOrUndefinedOrEmpty(store))
		{
			return false;
		}
		else if (this.isNullOrUndefinedOrEmpty(store.state))
		{
			return false;
		}
		else if (this.isNullOrUndefinedOrEmpty(store.state.permissions))
		{
			return false;
		}

		// I permessi ci sono, verifico se l'utente è abilitato al permesso selezionato
		return this.isPageEnabled(store, pageAuth);
	}

	/// Definisce se una pagina è abilitata per l'utetne selezionato
	isPageEnabled(store, pageAuth) {
        var isEnab = false;
        for (let i = 0; i < store.state.permissions.length; i++) {
          var perm = store.state.permissions[i];
          if (perm.CCode == pageAuth) {
            isEnab = true;
            break;
          }
        }
        return isEnab;
    }

	getTipologicheAttive(tipologiche) {
		var newTipologiche = [];
		for (let i = 0; i < tipologiche.length; i++) {
			var tipol = tipologiche[i];
			if (tipol.flagVisibile) {
				var tipolTmp = Object.assign({}, tipol);
				newTipologiche.push(tipolTmp);
			}
		}
		return newTipologiche;
	}

	cloneArray(arrDaClonare) {
		var newArray = [];
		for (let i = 0; i < arrDaClonare.length; i++) {
			var obj = arrDaClonare[i];
			var objTmp = Object.assign({}, obj);
			newArray.push(objTmp);
		}
		return newArray;
	}

	onKeyDownDecimal(key) {
		if (key.key == "." || key.key == ",")
		{
		  key.preventDefault();
		}
		else
		  return true;
	  }

	onKeyDownDecimalAndNegative(key) {
		if (key.key == "." || key.key == "," || key.key == "-")
		{
			key.preventDefault();
		}
		else
			return true;
		}

	onKeyDownNegative(key) {
		if (key.key == "-")
		{
		  key.preventDefault();
		}
		else
		  return true;
	  }
}

export default new Utils();
