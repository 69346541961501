<template>
  <router-view />
</template>

<script>
import AuthService from '@/service/AuthService';
import HttpInterceptor from '@/interceptor/HttpInterceptor';

  export default {
    name: 'App',
    mounted(){
      new HttpInterceptor();
      AuthService.initAuthService();
    }
  }
</script>
