import jwt_decode from 'jwt-decode';
import axios from 'axios';
import router from '@/router'
import { EventBus } from '@/interceptor/EventBus';
import { environment } from '@/environment/environment';
import Utils from "@/utils/Utils";

const TOKEN_KEY = 'access_token';
const REFRESH_INTERVAL = 180000; // 270000; // 4.5 min // 870000; //14,5 min
const COURSE_API_URL = environment.urlSrc;
const AUTH_API_URL = COURSE_API_URL + "Token/";


let AuthService = /** @class */ (() => {
  class AuthService {

    static refreshInterval = null;

    static initAuthService(callbackFunction) {
      //this.refreshTokens(callbackFunction);
      // Disabilito il refresh in quanto è implementato lato backend
      this.startRefreshInterval();
    }
    static startRefreshInterval() {
      this.refreshInterval = setInterval(() => this.refreshTokens(null), REFRESH_INTERVAL);
    }
    static stopRefreshInterval() {
      clearInterval(this.refreshInterval);
    }
    static isAuthenticatedUser() {
      return !Utils.isNullOrUndefinedOrEmpty(this.getToken());
    }
    static setToken(accessToken) {
      localStorage.setItem(TOKEN_KEY, accessToken)
    }
    static setTokenFromResponse(status, jwt) {
      if (status == 200) {
        this.setToken(jwt);
        //this.setAuthority();
      } else {
        this.removeToken();
        if (status == 401) {
          /*
          EventBus.openDialog({
            dialogShow: true,
            dialogCancel: null,
            dialogConfirm: function() { window.location.reload() },
            dialogOutside: function() { window.location.reload() },
            dialogTitle: 'login.error.alreadyLoggedTitle',
            dialogText: 'login.error.alreadyLoggedText',
          });
          */
          console.log("Rimozione token, redirect login")
          router.push({
            name: "Login",
          });
        }
      }
    }
    static setAuthority() {
      environment.authority = this.getAuthority().map(auth => {
        return auth.authority;
      });
    }
    static getToken() {
      //var myCookie = document.cookie;
      //let aToken = myCookie.replace("X-Access-Token=", "");
      return this.getCookie("X-Access-Token");

      //return localStorage.getItem(TOKEN_KEY);
    }
    static getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
    static removeToken() {
      localStorage.clear()
      sessionStorage.clear()
    }
    static decodeToken() {
      return jwt_decode(this.getToken());
    }
    static getData() {
      return this.decodeToken().data;
    }
    static getAuthority() {
      return this.decodeToken().authority;
    }
    static checkAuthority(grant) {
      if (environment.authority) {
        return environment.authority.includes(grant);
      } else {
        return false
      }
    }
    static getUserInfo() {
      return this.getData().user;
    }
    static getClientInfo() {
      return this.getData().client;
    }
    static getClientId() {
      return this.getData().clientId;
    }
    static getRoleId() {
      return this.getData().roleId;
    }
    static getIsProfessional() {
      return this.getData().isProfessional;
    }
    static login(user, psw, callbackOk, callbackKo) {
      axios({
        method: "POST",
        data: {
          CUsername: user,
          CPassword: psw
        },
        url: COURSE_API_URL + "User/Authenticate",
        withCredentials: true,
      }).then((response) => {
        this.setTokenFromResponse(response.status, response.data.token.jwt);
        //this.initAuthService(null);
        if (callbackOk != null) {
          callbackOk(response.data);
        }
        router.push({ name: "Dashboard" });
      }).catch((err) => {
        console.log("Authenticate", err)
        if (callbackKo != null) {
          callbackKo(err);
        }
      });
    }
    static logout(callbackFunction) {
      axios({ method: 'GET', data: this.getToken(), headers: { "Content-Type": "application/json" }
        ,url: AUTH_API_URL + 'RevokeToken', withCredentials: true 
      }).then(() => {
        this.removeToken();
        sessionStorage.removeItem('user');

        if (callbackFunction != null) {
          callbackFunction();
        }
        router.push({ name: "Login" }).catch(() => { });
      }).catch(function (error) {
        console.log("RevokeToken", error);
        this.removeToken();
        router.push({ name: "Login" }).catch(() => { });
      });
    }
    static refreshTokens(callbackFunction) {
      /*
      axios({
        method: 'POST', params: {
          token: this.getToken()
        },
        url: AUTH_API_URL + 'Refresh', withCredentials: true
        */
      axios({ method: 'GET',/*'POST', data: this.getToken(),*/ headers: { "Content-Type": "application/json" }
      ,url: AUTH_API_URL + 'Refresh', withCredentials: true 
      }).then((response) => {
        this.setTokenFromResponse(response.status, response.data.jwt);

        if (callbackFunction != null) {
          callbackFunction();
        }
      }).catch((error) => {
        //let data = error.response.data
        //EventBus.setSnackbarFromResponse(error.response)
        console.log("Refresh", error)
        if ((error.response.status == 401)) {
          this.logout();
        }
      });
    }
  }
  AuthService.accessToken = null;
  return AuthService;
})();
export default AuthService;
