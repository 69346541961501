import Vue from 'vue';

export const EventBus = new Vue();

EventBus.setSnackbar = function(data){
    EventBus.$emit("showSnackbar", {
        msg: data.msg,
        success: data.success
    })
}

EventBus.setSnackbarFromResponse = function(response){
    if(!response.msg){
        return;
    }

    EventBus.setSnackbar({
        msg: response.msg,
        success: response.success
    })
}

EventBus.openDialog = function(data){
    EventBus.$emit("openDialog", data);
}

EventBus.showPageLoader = function(){
    EventBus.$emit("showPageLoader");
}
EventBus.hidePageLoader = function(){
    EventBus.$emit("hidePageLoader");
}

EventBus.onChangeLanguage = function (data) {
    EventBus.$emit("onChangeLanguage");
}

EventBus.onOpenLanguageDialog = function (data) {
    EventBus.$emit("openLanguageDialog");
}
EventBus.onCloseChangeLanguageDialog = function (data) {
    EventBus.$emit("closeChangeLanguageDialog");
}

EventBus.onStartUpload = function (item, file, fileExtra) {
    EventBus.$emit("startUpload", item, file, fileExtra);
}
EventBus.onEndUpload = function (itemId) {
    EventBus.$emit("endUpload", itemId);
}

EventBus.onCloseIframeViewerLite = function(){
    EventBus.$emit("closeIframeViewerLite");
}

EventBus.onChooseCoordinatesForItem = function (coordinates) {
    EventBus.$emit("chooseCoordinatesForItem", coordinates);
}

EventBus.catchNotifications = function (notifications) {
    EventBus.$emit("catchNotifications", notifications);
}
EventBus.openNotificationCenter = function (openCommand) {
    EventBus.$emit("openNotificationCenter", openCommand);
}
EventBus.closeNotificationCenter = function (closeCommand) {
    EventBus.$emit("closeNotificationCenter", closeCommand);
}

EventBus.callReadNotification = function (idNotification) {
    EventBus.$emit("callReadNotification", idNotification);
}
EventBus.callReadAllNotification = function (notifications) {
    EventBus.$emit("callReadAllNotification", notifications);
}
EventBus.callServiceNotification = function (idNotification, choice) {
    EventBus.$emit("callServiceNotification", idNotification, choice);
}

EventBus.openUploadCenter = function (openCommand) {
    EventBus.$emit("openUploadCenter", openCommand);
}
EventBus.closeUploadCenter = function (closeCommand) {
    EventBus.$emit("closeUploadCenter", closeCommand);
}
EventBus.catchUploadItems = function (itemsToUpload) {
    EventBus.$emit("catchUploadItems", itemsToUpload);
}

EventBus.closeDialogSetCoordinateMap = function (signalToCloseDialog) {
    EventBus.$emit("closeDialogMap", signalToCloseDialog)
}

EventBus.openDialogPreviewItems = function (itemToPreview) {
    EventBus.$emit("openDialogPreviewItems", itemToPreview);
}
